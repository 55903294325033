<template>
  <v-card
    id="order-customer-detail"
    class="order-detail-card"
    :height="$vuetify.breakpoint.xsOnly ? 'auto' : '100%'">
    <v-col
      v-if="showStatusChip"
      id="status-container"
      :cols="12"
      class="elevation-1">
      <div class="d-flex justify-center my-2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :class="[
                `btn-order btn-status-${orderStatusState}`,
                hasActionList ? 'justify-space-between' : 'justify-center'
              ]"
              block
              v-bind="attrs"
              v-on="hasActionList ? on : null">
              <div v-if="hasActionList"></div>
              <div class="d-flex flex-row justify-center">
                <gw-svg-icon
                  v-if="orderStatusState !== 'prepare_create' && orderStatusState !== 'edit'"
                  :svg-name="`order-${orderStatusState}`"
                  width="15px"
                  height="15px"
                  class="order-timeline-icon"
                  color="#ffffff" />
                <span class="ml-2">
                  {{ toCapitalize(orderStatusState) }}
                </span>
              </div>
              <v-icon v-if="hasActionList">
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group>
              <v-list-item
                v-for="(item) in actionList"
                :key="item"
                dense
                @click="orderAction(item)">
                <v-list-item-action-text>
                  {{ item }}
                </v-list-item-action-text>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </v-col>
    <v-col
      id="customer-detail-container"
      class="elevation-1"
      :cols="12">
      <v-row
        class="customer-detail-row"
        no-gutters>
        <v-col
          :cols="12"
          class="pb-2">
          <h3>Customer Detail</h3>
        </v-col>
        <v-col :cols="12">
          <div class="d-flex">
            <v-text-field
              :value="member.tel"
              :disabled="isGotMember || !editMode"
              label="Tel"
              dense
              @input="updateMemberTel($event)" />
            <template v-if="!inputDisabled">
              <v-btn
                v-if="isGotMember"
                :disabled="!editMode"
                class="mt-2 ml-2"
                x-small
                @click="resetMemberData()">
                <v-icon x-small>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                class="mt-2 ml-2"
                :disabled="!editMode"
                x-small
                @click="getMember()">
                <v-icon x-small>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </div>
          <v-text-field
            :value="member.firstName"
            :disabled="isDisableFirstName || !editMode"
            label="First Name"
            dense
            @input="updateMemberFirstName($event)" />
          <v-text-field
            :value="member.lastName"
            :disabled="isDisableLastName || !editMode"
            label="Last Name"
            dense
            @input="updateMemberLastName($event)" />
          <v-text-field
            :value="member.email"
            :disabled="isDisableEmail || !editMode"
            label="Email"
            dense
            @input="updateMemberEmail($event)" />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      id="tab-container"
      :cols="12"
      :style="{ height: `calc(100vh - ${tabContainerHeightOffset})`, overflowY: 'auto' }">
      <v-menu
        bottom
        offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            block
            color="secondary"
            class="d-flex justify-space-between"
            v-bind="attrs"
            v-on="on">
            <div />
            {{ tabItems[tab] }}
            <v-icon>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in tabItems"
            :key="index"
            @click="selectTab(index)">
            <v-list-item-title>
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="enableCeleb">
          <v-text-field
            v-model="celebSearch"
            class="my-1"
            placeholder="ig, fb, เบอร์โทร, อีเมล หรือ ชื่อ"
            dense
            hide-details
            outlined
            append-icon="mdi-magnify"
            @click:append="fetchCelebs(celebSearch)" />
          <div :style="{ height: `calc(100vh - ${tabContainerHeightOffset} - 160px)`, overflowY: 'auto' }">
            <celeb-box
              v-for="(celeb, index) in celebs"
              :key="`celeb-box-${index}`"
              :item="celeb"
              :active="celebSelected === index"
              @on-click="onSelectCeleb($event, index)" />
          </div>
          <p class="text-caption mb-0 mt-2">
            *ข้อมูลจะแสดงสูงสุด 50 แถว, หากไม่พบข้อมูลที่ต้องการกรุณาลองใช้ช่องค้นหาด้านบน
          </p>
        </v-tab-item>
        <v-tab-item v-else>
          <order-address
            :is-edit-address.sync="isEditAddress"
            :shipping-address="shippingAddress"
            :member-id="member.id"
            :input-disabled="inputDisabled"
            :height-offset="tabContainerHeightOffset" />
        </v-tab-item>
        <v-tab-item>
          <order-package />
        </v-tab-item>
        <v-tab-item>
          <order-payment :payments="payments" />
        </v-tab-item>
        <v-tab-item>
          <order-attachments />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <void-reason
      v-model="isShowVoidReason"
      :reason="orderDetail.voidReason || ''"
      :readonly="orderStatusState === 'void' || orderStatusState === 'exchange_return'"
      @confirm="confirmVoidOrder($event)" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import MemberProvider from '@/resources/MemberProvider'
import OrderProvider from '@/resources/OrderProvider'
import CelebProvider from '@/resources/CelebProvider'
import DHLProvider from '@/resources/DHLProvider'
import ShippingCountries from '@/assets/json/ShippingCountries.json'

import OrderAddress from './OrderAddress.vue'
import OrderPayment from './OrderPayment.vue'
import OrderPackage from './OrderPackage.vue'
import OrderAttachments from './OrderAttachments.vue'
import CelebBox from './CelebBox.vue'
import VoidReason from './VoidReason.vue'

const OrderService = new OrderProvider()
const CelebService = new CelebProvider()
const DHLServices = new DHLProvider()

export default {
  components: {
    OrderAddress,
    OrderPayment,
    OrderPackage,
    OrderAttachments,
    CelebBox,
    VoidReason
  },
  props: {
    payments: {
      type: Array,
      default: () => []
    },
    inputDisabled: {
      type: Boolean,
      default: false
    },
    shippingAddress: {
      type: Object,
      requried: true,
      default: () => ({
        address: 'ที่อยู่',
        address2: '',
        contactNo: '',
        country: 'ประเทศ',
        district: 'เขต/อำเภอ',
        email: 'อีเมล',
        firstName: 'ชื่อ',
        lastName: 'นามสกุล',
        note: '',
        postcode: '',
        shippingName: '',
        stateProvice: 'จังหวัด',
        subDistrict: 'แขวง/ตำบล',
        taxId: '',
        title: ''
      })
    }
  },
  data () {
    return {
      tab: 0,
      isEditAddress: false,
      isDisableFirstName: false,
      isDisableLastName: false,
      isDisableEmail: false,
      celebs: [],
      celebSearch: '',
      celebSelected: -1,
      shippingCountries: ShippingCountries,
      tabContainerHeightOffset: '100%',
      isShowVoidReason: false
    }
  },
  computed: {
    ...mapGetters({
      currencyUnit: 'Order/currencyUnit',
      orderDetail: 'Order/orderDetail',
      shipping: 'Order/shipping',
      isGotMember: 'Order/isGotMember',
      isMainOrder: 'Order/isMainOrder',
      orderStatusState: 'Order/orderStatusState',
      editMode: 'Order/onEditMode',
      backupOrderDetail: 'Order/backupOrderDetail'
    }),
    enableCeleb () {
      return this.orderDetail?.channel === 'celeb' && this.editMode
    },
    tabItems () {
      if (this.isMainOrder && this.orderDetail?.channel === 'celeb') {
        return ['Celebs', 'Packages', 'Payment']
      }

      if (this.isMainOrder) {
        return ['Address', 'Packages', 'Payment']
      }

      if (this.orderDetail?.channel === 'celeb') {
        return ['Celebs', 'Packages', 'Payment', 'Attachments']
      }

      return ['Address', 'Packages', 'Payment', 'Attachments']
    },
    member () {
      return this.orderDetail?.member || {
        firstName: '',
        lastName: '',
        email: '',
        tel: ''
      }
    },
    showStatusChip () {
      if (this.orderDetail.status === 'deleted') {
        return false
      }
      return this.orderStatusState && this.orderStatusState !== 'prepare_create'
    },
    actionList () {
      if (this.orderStatusState === 'void' || this.orderStatusState === 'exchange_return') {
        return []
      }

      if (this.orderDetail.channel === 'line_myshop') {
        return ['Void Order']
      }

      const items = []

      if (this.isMainOrder) {
        if (!this.$vuetify.breakpoint.xsOnly) {
          items.push('Edit')
        }
        items.push('Void Order')
      }

      if (this.isMainOrder && this.orderStatusState === 'pending') {
        items.push('Pay Order')
      }

      return items
    },
    hasActionList () {
      return this.actionList.length > 0
    }
  },
  watch: {
    orderStatusState () {
      this.tab = 0
    },
    tab () {
      this.isEditAddress = false
    },
    gotMember (val) {
      if (val) {
        if (this.orderStatusState === 'prepare_create' || this.orderStatusState === 'editing') {
          if (this.memberFirstName) {
            this.isDisableFirstName = true
          }
          if (this.memberLastName) {
            this.isDisableLastName = true
          }
          if (this.memberEmail) {
            this.isDisableEmail = true
          }
        } else {
          this.isDisableFirstName = true
          this.isDisableLastName = true
          this.isDisableEmail = true
        }
      }
    }
  },
  mounted () {
    this.fetchCelebs('')
    setTimeout(() => {
      const statusContainerHeight = document.getElementById('status-container')?.clientHeight || 0
      const customerDetailContainerHeight = document.getElementById('customer-detail-container')?.clientHeight || 0

      this.tabContainerHeightOffset = `${statusContainerHeight + customerDetailContainerHeight + 150}px`
    }, 300)
  },
  methods: {
    ...mapActions({
      getMember: 'Order/getMember',
      updateMemberTel: 'Order/updateMemberTel',
      updateGotMember: 'Order/updateGotMember',
      updateMemberId: 'Order/updateMemberId',
      updateMemberFirstName: 'Order/updateMemberFirstName',
      updateMemberLastName: 'Order/updateMemberLastName',
      updateMemberEmail: 'Order/updateMemberEmail',
      setOrderDetail: 'Order/setOrderDetail',
      setBackupOrderDetail: 'Order/setBackupOrderDetail',
      setShippingFee: 'Order/setShippingFee',
      setOrderDetailDHLPackages: 'Order/setOrderDetailDHLPackages',
      setLoading: 'Components/setLoading',
      setSnackbar: 'Components/setSnackbar'
    }),
    toCapitalize (text) {
      return text.split('_').join(' ')
    },
    selectTab (index) {
      this.tab = index
    },
    orderAction (action) {
      switch (action) {
        case 'Edit':
          this.editOrder()
          break
        case 'Void Order':
          this.isShowVoidReason = true
          break
        case 'Pay Order':
          this.paid(this.orderDetail)
          break
        default:
          console.error('orderAction not found.')
          break
      }
    },
    confirmVoidOrder (voidReason) {
      this.voidOrder({
        ...this.orderDetail,
        voidReason
      })
    },
    async fetchCelebs (search) {
      try {
        this.loading = true
        const { data } = await CelebService.getCelebs({ search, itemsPerPage: 50 })
        this.celebs = data.results
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[error on fetch celeb]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async paid (payload) {
      let response = null
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'PAYING ORDER...'
        })
        const { data } = await OrderService.updateStatusToPaid(payload.orderId)
        this.$emit('close-modal-request')
        response = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }

      return response
    },
    editOrder () {
      this.beforeEdit()
    },
    beforeEdit () {
      this.setBackupOrderDetail(JSON.parse(JSON.stringify(this.orderDetail)))
      this.setOrderDetail({ ...this.orderDetail, promotionCode: {}, discount: 0, currentState: 'edit' })
    },
    async voidOrder (order) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'VOIDING ORDER...'
        })

        this.$emit('close-modal-request')

        const { message } = await OrderService.voidOrder(order.orderId, order.voidReason)

        if (message === 'done') {
          this.setSnackbar({
            value: true,
            message: 'Order has voided',
            type: 'success'
          })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    resetMemberData () {
      this.isDisableFirstName = false
      this.isDisableLastName = false
      this.isDisableEmail = false
      this.updateGotMember(false)
      this.cancelVerifyCode()
    },
    cancelVerifyCode () {
      this.$emit('cancelVerifyCode')
    },
    onSelectCeleb (item, index) {
      this.celebSelected = index

      const note = this.socialCompute(item.socialMedias)
      this.setOrderDetail({ ...this.orderDetail, shippingAddress: { ...item.address, note } })
      this.checkDHLRate(item.address)
    },
    socialCompute (socials) {
      let text = ''
      if (socials.length) {
        const instagram = socials.find((social) => social.platform === 'instagram')
        const facebook = socials.find((social) => social.platform === 'facebook')

        if (instagram) {
          text = `(ig: ${instagram.accountName})`
        } else if (facebook) {
          text = `(fb: ${facebook.accountName})`
        } else if (socials[0]) {
          text = `(${socials[0].platform}: ${socials[0].accountName})`
        }
      }

      return text
    },
    async checkDHLRate (address) {
      try {
        if (!this.orderDetail.skus.length || this.shipping.method !== 'dhl_express') {
          return
        }

        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Getting Rates...'
        })

        const foundCountry = this.shippingCountries.find((c) => c.country.toLowerCase() === address.country.toLowerCase())

        if (!foundCountry) {
          throw Error('Country or postal code was wrong.')
        }

        const skus = this.orderDetail.skus.map((sku) => ({
          id: sku.id,
          productId: sku.productId,
          amount: sku.amount,
          tags: sku.tags
        }))

        const { data } = await DHLServices.getRates({
          countryCode: foundCountry.code,
          cityName: address.stateProvince,
          postalCode: (foundCountry.code === 'VN' || foundCountry.code === 'MO') ? '' : address.postcode,
          plannedShippingDate: this.$dayjs().add(1, 'd').format('YYYY-MM-DD'),
          skus
        })

        if (this.currencyUnit !== 'THB') {
          this.setShippingFee(data.price.usd)
        } else {
          this.setShippingFee(data.price.thb)
        }
        this.setOrderDetailDHLPackages(data?.namedPackages || [])
      } catch (error) {
        console.error('checkDHLRate', error)
        this.setSnackbar({
          value: true,
          message: 'Country or postal code was wrong.',
          type: 'error'
        })
        throw error
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/gw-variables.scss';

#order-customer-detail {
  .payment-box p,
  .shipping-address-box p {
    margin-bottom: 0;
    font-size: 12px;
    color: #000;
  }
  .payment-box {
    position: relative;
    margin: 15px 0;
    padding: 10px 10px 10px 30px;
    background-color: #f0f0f0;
  }
  .payment-box::before {
    content: attr(data-count);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    font-size: 10px;
    position: absolute;
    top: -9.5px;
    left: 5px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);;
  }
  .payment-box span:first-child,
  .shipping-address-box span:first-child {
    margin-right: 5px;
    font-weight: bold;
  }
  .v-btn.btn-order {
    background-color: $bill-status;
    color: #fff;
  }
  .v-btn.btn-status-pending {
    background-color: $bill-status-pending;
  }
  .v-btn.btn-status-reserved {
    background-color: $bill-status-reserved;
  }
  .v-btn.btn-status-update_shipping {
    background-color: $bill-status-update_shipping;
  }
  .v-btn.btn-status-paid {
    background-color: $bill-status-paid;
  }
  .v-btn.btn-status-waiting {
    background-color: $bill-status-waiting;
  }
  .v-btn.btn-status-ready_to_ship {
    background-color: $bill-status-readytoship;
  }
  .v-btn.btn-status-completed {
    background-color: $bill-status-completed;
  }
  .v-btn.btn-status-void {
    background-color: $bill-status-void;
  }
  .v-btn.btn-status-exchange_return {
    background-color: $bill-status-exchange_return;
  }
  .v-btn.btn-status-expired {
    background-color: $bill-status-expired;
  }
}
</style>
