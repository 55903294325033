<template>
  <div>
    <v-dialog
      :value="modalActive"
      max-width="1200"
      width="95%"
      elevation="0"
      @input="toggleModalProduct($event)">
      <v-row
        id="product-modal"
        no-gutters>
        <v-col :cols="9">
          <v-card class="product-card">
            <v-row class="ma-0 product-header pa-4 elevation-1">
              <v-col cols="auto">
                <WarehouseSelect
                  v-model="warehouseSelected"
                  :items="mapWarehouse"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                  label="Warehouses"
                  return-object
                  outlined />
              </v-col>
              <v-spacer />
              <v-col
                cols="7"
                class="d-flex align-center col-search">
                <search-by-tags
                  append-icon="mdi-magnify"
                  tags-width="300px"
                  :tags="tags"
                  @on-search="searchProduct($event)" />
              </v-col>
            </v-row>
            <v-row
              v-if="!getting"
              ref="product-order-list"
              class="ma-0 product-list pa-4 elevation-1"
              @scroll="handleScroll($event)">
              <order-product-list
                v-for="(product, index) in products"
                :key="`product-${product.id}`"
                :product-id="product.id"
                :product-name="product.name"
                :model="product.model"
                :brand="product.brand"
                :variant="product.variant"
                :skus="product.skus"
                :photo-urls="product.photoUrls"
                :warehouse-id="warehouseSelected.id"
                @skuSelecting="skuSelecting($event, index)" />
            </v-row>
            <v-row v-else>
              <v-overlay
                :value="getting">
                <v-progress-circular
                  indeterminate
                  size="64" />
              </v-overlay>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="product-card">
            <v-row class="ma-0 product-cart-header pa-4 elevation-1">
              <v-col :cols="12">
                <h2>Cart</h2>
              </v-col>
            </v-row>
            <v-row class="ma-0 product-cart-body pa-4 elevation-1">
              <v-col
                class="pa-0"
                :cols="12">
                <div
                  v-for="(item, index) in cart"
                  :key="`cartitem-${item.id}-${item.warehouse.id}`"
                  class="cart-item py-3">
                  <gw-icon-hover
                    small
                    class="cart-bin"
                    icon-name="mdi-delete"
                    icon-hover="mdi-delete-empty"
                    @click="removeItem(index)" />
                  <div class="cart-image mr-2">
                    <gw-product-image
                      :src="item.images[0]"
                      custom-svg="auto" />
                    <div
                      v-if="getPreOrder(item)"
                      class="d-flex justify-center mt-1">
                      <v-chip
                        color="orange"
                        dark
                        x-small>
                        Pre-Order
                      </v-chip>
                    </div>
                  </div>
                  <div class="cart-detail">
                    <h3>{{ item.name }}</h3>
                    <p>
                      <b>Color : </b>
                      {{ item.color }}
                    </p>
                    <p>
                      <b>Size : </b>
                      {{ item.size }}
                    </p>
                    <p>
                      <b>Price : </b>
                      {{ item.unitPrice | showFullPriceFormat() }}
                    </p>
                    <p>
                      <b>Warehouse : </b>
                      {{ item.warehouse.name }}
                    </p>
                    <v-text-field
                      v-model.number="item.amount"
                      class="amount-input"
                      type="number"
                      min="1"
                      hide-details
                      outlined
                      dense
                      @input="setAmount(item, index)">
                      <template v-slot:prepend>
                        <p>
                          <b>Amount : </b>
                        </p>
                      </template>
                    </v-text-field>
                    <p>
                      <b>Total : </b>
                      {{ item.fullPrice | showFullPriceFormat() }}
                    </p>
                    <!-- <v-checkbox
                      v-model="item.mixAndMatch"
                      label="Mix & Match"
                      class="check-mixandmatch"
                      :ripple="false"
                      dense
                      hide-details
                      @change="toggleMixAndMatch(index, $event)" /> -->
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="ma-0 product-cart-footer pa-4 elevation-1">
              <v-col class="summery-cart">
                <p>
                  <b>Total Items : </b>
                  {{ cartTotalAmount | showNumberFormat() }}
                </p>
                <p>
                  <b>Total Price : </b>
                  {{ cartTotalPrice | showFullPriceFormat() }}
                </p>
                <v-btn
                  color="primary"
                  block
                  @click="checkout()">
                  Checkout
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defaultOrderSku } from '@/assets/js/DefaultOrderData'
import CalculateMixAndMatchPricing from '@/assets/js/CalculateMixAndMatchPricing'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import ProductProvider from '@/resources/ProductProvider'
import PromotionProvider from '@/resources/PromotionProvider'
import CurrencyRatesProvider from '@/resources/CurrencyRatesProvider'
import SearchByTags from '@/components/SearchByTags.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import OrderProductList from './OrderProductList.vue'

const ProductAttributeService = new ProductAttributeProvider()
const ProductService = new ProductProvider()
const PromotionService = new PromotionProvider()
const CurrencyRatesService = new CurrencyRatesProvider()

export default {
  components: {
    orderProductList: OrderProductList,
    SearchByTags,
    WarehouseSelect
  },
  data () {
    return {
      products: [],
      tags: [],
      tagsSelected: [],
      page: 0,
      perPage: 15,
      totalPage: 1,
      getting: false,
      isLoadComplete: false,
      searchText: '',
      tagOperation: 'OR',
      warehouseSelected: null,
      currencyMapping: {
        thb: 35,
        foriegnCurrency: {
          isoCode: 'usd',
          label: 'usd',
          value: 1
        }
      },
      cartItems: [],
      cartTotalAmount: 0,
      cartTotalPrice: 0,
      isFirstFetch: true
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      productModalActive: 'Order/productModalActive',
      orderDetail: 'Order/orderDetail'
    }),
    cart () {
      this.calcTotal(this.cartItems)
      return this.cartItems
    },
    modalActive () {
      const modalActive = this.productModalActive
      if (modalActive) {
        this.modalActived()
      } else {
        this.modalDeactived()
      }
      return modalActive
    }
  },
  async created () {
    await this.getCurrencyRates()
    window.addEventListener('scroll', this.handleScroll)
    this.getTags()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      calcSkuPrice: 'Order/calcSkuPrice',
      setProductModalActive: 'Order/setProductModalActive',
      setSkus: 'Order/setSkus',
      setOrderDetail: 'Order/setOrderDetail',
      setAllSubOrders: 'Order/setAllSubOrders'
    }),
    mapCartItems (subOrders = []) {
      let cartItems = []
      subOrders.forEach((subOrder) => {
        if (subOrder.warehouse.id !== 0) {
          const skus = subOrder.skus?.map((each) => ({ ...each, unitPrice: each.fullPrice / each.amount })) || []
          cartItems = [...cartItems, ...skus]
        }
      })

      return cartItems
    },
    modalActived () {
      if (!this.cartItems.length && this.isFirstFetch) {
        this.cartItems = this.mapCartItems(this.orderDetail.subOrders)
      }

      this.isFirstFetch = false

      if (!this.warehouseSelected) {
        this.setWarehouseSelected(0)
      }

      if (!this.products.length) {
        this.fetchProducts()
      }
    },
    modalDeactived () {
      this.isLoadComplete = false
      this.page = 0
      this.getting = false
      this.isFirstFetch = true
      this.products = []
    },
    calcTotal (cart) {
      let totalAmount = 0
      let totalPrice = 0
      cart.forEach((item) => {
        totalAmount += item.amount
        totalPrice += item.fullPrice
      })
      this.cartTotalAmount = totalAmount
      this.cartTotalPrice = totalPrice
    },
    toggleModalProduct (event) {
      if (!event) {
        this.cartItems = []
      }
      this.setProductModalActive(event)
    },
    async checkout () {
      let pricing = {}
      try {
        const { data } = await PromotionService.getMixAndMacthPricingObject()
        pricing = { ...data }
      } catch (error) {
        console.error('checkout', error)
      }
      const skus = CalculateMixAndMatchPricing(pricing, this.cartItems)

      this.setSkus(skus)
      const subOrders = this.mapSubOrders(this.cartItems, skus)
      const resetedPrice = this.resetPrice(subOrders)

      this.setOrderDetail({
        ...this.orderDetail,
        promotionCode: {},
        discount: 0,
        skus,
        subOrders: resetedPrice
      })
      this.toggleModalProduct(false)
    },
    resetPrice (skus) {
      return skus.map((sku) => ({
        ...sku,
        skus: sku.skus.map((each) => {
          let result = each
          if (!each.mixAndMatch) {
            const rawPrice = each.unitPrice ? each.unitPrice * each.amount : each.fullPrice
            result = {
              ...each,
              discountPrice: 0,
              price: rawPrice
            }
          }

          return result
        })
      }))
    },
    fulfillCartItems (items) {
      return items.map((item) => ({
        ...item,
        unitPrice: item.unitPrice ? item.unitPrice : this.getUnitPrice(this.fullPrice, item.amount),
        discountPrice: item.discountPrice ? item.discountPrice : this.getDiscountPrice(this.fullPrice, item.price)
      }))
    },
    removeItem (index) {
      this.cartItems.splice(index, 1)
    },
    getUnitPrice (fullPrice, amount) {
      return fullPrice / amount || 0
    },
    getDiscountPrice (fullPrice, price) {
      return fullPrice - price || 0
    },
    mapSubOrders (cartItems, skus) {
      const subOrders = []

      cartItems.forEach((item) => {
        const isPreOrder = item?.preOrder || false
        const found = subOrders.findIndex((subOrder) => (subOrder.warehouse.id === item.warehouse.id) && (subOrder.preOrder === isPreOrder))
        const discountPerAmount = this.getDiscountByProductId(skus, item.id)
        const rawPrice = item.unitPrice ? item.unitPrice * item.amount : item.fullPrice
        const tempItem = {
          ...item,
          discountPrice: discountPerAmount * item.amount,
          price: rawPrice - (discountPerAmount * item.amount),
          unitPrice: item.unitPrice ? item.unitPrice : this.getUnitPrice(rawPrice, item.amount)
        }

        if (found === -1) {
          subOrders.push({
            preOrder: isPreOrder,
            skus: [tempItem],
            warehouse: item.warehouse
          })
        } else {
          subOrders[found].skus.push(tempItem)
        }
      })

      return subOrders
    },
    getDiscountByProductId (discounts, id) {
      const found = discounts.find((discount) => discount.id === id)
      return found?.discountPrice / found?.amount || 0
    },
    setAmount (item, index) {
      const { amount } = item
      const fullPrice = item.unitPrice * amount
      const price = fullPrice - item.discountPrice
      this.spliceItemCart({
        index,
        item: {
          ...item,
          amount,
          fullPrice,
          price
        }
      })
    },
    toggleMixAndMatch (index, event) {
      const sku = this.cartItems[index]
      const newCart = []
      this.cartItems.forEach((item) => {
        const newItem = item
        if (item.id === sku.id) {
          newItem.mixAndMatch = event
        }
        newCart.push(newItem)
      })
      this.cartItems = newCart
    },
    skuSelecting (sku, index) {
      const product = this.products[index]
      const mapSku = {
        ...defaultOrderSku,
        id: sku.id,
        code: sku.code,
        productId: product.id,
        name: product.name,
        color: sku.color.name,
        size: sku.size.name,
        option: sku.option,
        description: product.description,
        isOnweb: product.isOnWeb,
        images: product.photoUrls,
        categories: product.categories,
        groupCategories: product.groupCategories || [],
        gwCollection: product?.gwCollection?.id || undefined,
        tags: product.tags,
        brand: product.brand,
        variant: product.variant,
        preOrder: sku?.preOrder || false,
        preOrderNote: sku?.preOrderNote || '',
        unitPrice: sku.price,
        fullPrice: sku.price,
        price: sku.price,
        discountPrice: 0,
        amount: 1,
        mixAndMatch: false,
        warehouse: {
          ...this.warehouseSelected
        }
      }

      this.addToCart(mapSku)
    },
    addToCart (sku) {
      const duplicateIndex = this.cartItems.findIndex((item) => item.id === sku.id && item.warehouse.id === sku.warehouse.id)
      const sameItemIndex = this.cartItems.findIndex((item) => item.id === sku.id)

      if (duplicateIndex === -1) {
        const mappedSku = sameItemIndex !== -1 ? { ...sku, mixAndMatch: this.cartItems[sameItemIndex].mixAndMatch } : sku
        this.cartItems.push(mappedSku)
      } else {
        const item = this.cartItems[duplicateIndex]
        this.setAmount({
          ...item,
          mixAndMatch: item.mixAndMatch,
          amount: item.amount + sku.amount
        }, duplicateIndex)
      }
    },
    spliceItemCart (sku) {
      this.cartItems.splice(sku.index, 1, sku.item)
    },
    setWarehouseSelected (index) {
      this.warehouseSelected = this.mapWarehouse[index]
    },
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight - 100
      const isScroll = e.scrollTop >= scrollY
      if (!this.isLoadComplete && isScroll && !this.getting) {
        this.fetchProducts()
      }
    },
    searchProduct (attr) {
      this.page = 0
      this.products = []

      this.searchText = attr?.search || ''
      this.tagsSelected = attr?.tags || []
      this.tagOperation = attr?.tagOperation || 'OR'

      this.fetchProducts()
    },
    async fetchProducts () {
      try {
        this.getting = true

        if (this.page < this.totalPage) {
          this.page++
          const { data } = await ProductService.getProducts({
            page: this.page,
            limit: this.perPage,
            search: this.searchText,
            tags: this.tagsSelected,
            tagOperation: this.tagOperation
          })
          this.totalPage = data.pages
          this.totalProduct = data.total

          if (
            this.orderDetail.channel === 'international_manual'
            || this.orderDetail.channel === 'online_international'
          ) {
            const results = data.results.map((p) => {
              const skus = p.skus.map((sku) => {
                const price = Math.ceil(p.price / this.currencyMapping.thb)

                return {
                  ...sku,
                  price,
                  fullPrice: price
                }
              })

              return {
                ...p,
                skus
              }
            })

            this.products.push(...results)
          } else {
            this.products.push(...data.results)
          }
        }
      } catch (error) {
        console.error('fetchProducts', error)
        this.setErrorPage(error.code)
      } finally {
        this.isLoadComplete = this.page >= this.totalPage
        this.getting = false
      }
    },
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setErrorPage(error.code)
      }
    },
    async getCurrencyRates () {
      try {
        const { data } = await CurrencyRatesService.getCurrencyRates({
          page: 1
        })

        if (!data?.results?.length) {
          return
        }

        const currency = data.results[0]
        this.currencyMapping = {
          thb: currency.thb,
          foriegnCurrency: {
            isoCode: currency.isoCode,
            label: currency.label,
            value: currency.value
          }
        }
      } catch (error) {
        console.error('getCurrencyRates: ', error)
        this.setErrorPage(error.code)
      }
    },
    getPreOrder (item) {
      return item?.preOrder
    }
  }
}
</script>

<style lang="scss" scoped>
#product-modal {
  height: 95vh;
  font-size: 16px;
  position: relative;
  .product-header,
  .product-cart-header {
    z-index: 1;
    position: relative;
  }
  .product-detail-card {
    border-radius: 0;
  }
  .product-list {
    height: calc(100vh - 142px);
    overflow-y: auto;
  }
  .product-card {
    border-radius: 0;
  }
}
</style>

<style lang="scss">
#product-modal {
  .cart-detail p {
    font-size: 12px;
    margin: 0;
  }
  .cart-detail h3 {
    font-size: 14px;
  }
  .v-input.amount-input {
    .v-input__control {
      width: 70px;
    }
    input {
      text-align: center;
      font-size: 12px;
      padding: 0;
    }
  }
  .v-input.amount-input.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 28px;
    padding: 0 5px;
  }
  .product-cart-body {
    height: calc(100vh - 310px);
    overflow: auto;
    .cart-item {
      display: flex;
      position: relative;
      border-bottom: 1px solid #bdbdbd;
      height: fit-content;
      .cart-bin {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
      }
    }
  }
}
.v-input.check-mixandmatch {
  margin: 0;
  margin-left: -2px;
  padding: 0;
  label.v-label {
    font-size: 10px;
  }
  .v-input--selection-controls__input {
    margin: 0;
  }
}
</style>
