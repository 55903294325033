<template>
  <div>
    <v-tabs
      v-if="isMainOrder && orderDetail.status === 'active'"
      v-model="warehouseSelected">
      <v-tab
        v-for="(tab, index) in tabHeaders"
        :key="`tabs-index-${index}`">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-if="isMainOrder && orderDetail.status === 'active'"
      v-model="warehouseSelected"
      touchless>
      <v-divider></v-divider>
      <v-tab-item
        v-for="(tab, tabIndex) in tabHeaders"
        :key="`tab-items-index-${tabIndex}`"
        :value="tabIndex">
        <v-data-table
          id="order-product-table"
          :mobile-breakpoint="10"
          hide-default-footer
          class="pa-0"
          :items="allSubOrders[tab.id]"
          :headers="tabelHeader"
          :class="{ 'order-product-empty': !hasOrderSkus }"
          fixed-header
          :height="getHeightTable">
          <template #[`item.images`]="{ item }">
            <gw-product-image
              class="order-product-image"
              :src="imgSrcCompute(item.images[0])" />
            <div
              v-if="getPreOrder(item)"
              class="d-flex justify-center mt-1">
              <v-chip
                color="orange"
                dark
                x-small>
                Pre-Order
              </v-chip>
            </div>
          </template>
          <template #[`item.name`]="{ item }">
            <div class="order-product-name">
              <p class="mb-0 font-super-bold">
                {{ item.name }}
              </p>
              <p class="mb-0">
                <b>Model : </b>{{ item.code }}
              </p>
              <p class="mb-0">
                <b>Color : </b>{{ item.color }}
              </p>
              <p class="mb-0">
                <b>Size : </b>{{ item.size }}
              </p>
              <p
                v-if="!isMain(tab.id) && item.trackingNo"
                class="mb-0">
                <b>Tracking no. : </b>{{ item.trackingNo }}
              </p>
            </div>
          </template>
          <template #[`item.amount`]="{ item }">
            <v-text-field
              v-model.number="item.amount"
              disabled
              class="amount-input order-summary-input"
              type="number"
              min="1"
              dense
              solo
              outlined
              hide-details
              @input="calcSkuPrice({sku: item, index, amount: Number($event), field: 'amount'})" />
          </template>

          <template #[`item.price`]="{ item }">
            <p class="text-price">
              {{ getUnitPrice(item) | showFullPriceFormat() }}
            </p>
          </template>

          <template #[`item.discount`]="{ item }">
            <div class="d-flex">
              <v-text-field
                v-model.number="item.discountPrice"
                :disabled="item.mixAndMatch || inputDisabled || !editMode || !isMain(tab.id)"
                class="discount-input order-summary-input"
                dense
                solo
                outlined
                hide-details
                @keypress="$keypressNumber($event)"
                @input="calcSkuPrice({item, index})" />
              <v-select
                v-model="item.discountType"
                :items="discountType"
                :disabled="item.mixAndMatch || inputDisabled || !editMode || !isMain(tab.id)"
                :menu-props="{
                  offsetY: true,
                  contentClass: 'order-summary-select-menu'
                }"
                item-text="label"
                item-value="value"
                class="discount-select order-summary-select"
                hide-details
                dense
                outlined
                @change="calcSkuPrice({sku: item, index, discountType: $event})">
              </v-select>
            </div>
          </template>
          <template #[`item.net`]="{ item }">
            <p class="text-price">
              {{ item.price | showFullPriceFormat() }}
            </p>
          </template>
          <template #no-data>
            <v-btn
              :disabled="!editMode"
              color="#ffffff"
              icon
              block
              @click="openProductModal()">
              <gw-svg-icon
                svg-name="cart-plus-solid"
                width="75"
                heigth="75"
                color="#a4a4a4" />
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <v-data-table
      v-else
      id="order-product-table"
      class="pa-0"
      fixed-header
      :height="getHeightTable"
      :mobile-breakpoint="10"
      hide-default-footer
      :items="skus"
      :headers="tabelHeader"
      :class="{ 'order-product-empty': !hasOrderSkus }">
      <template #[`item.images`]="{ item }">
        <gw-product-image
          class="order-product-image"
          :src="imgSrcCompute(item.images[0])" />
        <div
          v-if="getPreOrder(item)"
          class="d-flex justify-center mt-1">
          <v-chip
            color="orange"
            dark
            x-small>
            Pre-Order
          </v-chip>
        </div>
      </template>
      <template #[`item.name`]="{ item }">
        <div class="order-product-name">
          <p class="mb-0 font-super-bold">
            {{ item.name }}
          </p>
          <p class="mb-0">
            <b>Model : </b>{{ item.code }}
          </p>
          <p class="mb-0">
            <b>Color : </b>{{ item.color }}
          </p>
          <p class="mb-0">
            <b>Size : </b>{{ item.size }}
          </p>
          <p
            v-if="shippingCode"
            class="mb-0">
            <b>Tracking no. : </b>{{ shippingCode }}
          </p>
        </div>
      </template>
      <template #[`item.amount`]="{ item }">
        <v-text-field
          v-model.number="item.amount"
          class="amount-input order-summary-input"
          disabled
          min="1"
          dense
          solo
          outlined
          hide-details />
      </template>

      <template #[`item.price`]="{ item }">
        <p class="text-price">
          {{ item.unitPrice | showFullPriceFormat() }}
        </p>
      </template>

      <template #[`item.discount`]="{ item }">
        <div class="d-flex">
          <v-text-field
            v-model.number="item.discountPrice"
            disabled
            class="discount-input order-summary-input"
            dense
            solo
            outlined
            hide-details
            @keypress="$keypressNumber($event)"
            @input="calcSkuPrice({item, index})" />
          <v-select
            v-model="item.discountType"
            :items="discountType"
            disabled
            :menu-props="{
              offsetY: true,
              contentClass: 'order-summary-select-menu'
            }"
            item-text="label"
            item-value="value"
            class="discount-select order-summary-select"
            hide-details
            dense
            outlined>
          </v-select>
        </div>
      </template>
      <template #[`item.net`]="{ item }">
        <p class="text-price">
          {{ item.price | showFullPriceFormat() }}
        </p>
      </template>
      <template #no-data>
        <v-btn
          :disabled="!editMode"
          color="#ffffff"
          icon
          block
          @click="openProductModal()">
          <gw-svg-icon
            svg-name="cart-plus-solid"
            width="75"
            heigth="75"
            color="#a4a4a4" />
        </v-btn>
      </template>
    </v-data-table>

    <v-btn
      v-if="!inputDisabled && editMode"
      class="order-product-add-btn"
      :ripple="false"
      :elevation="0"
      @click="openProductModal()">
      <v-icon color="#898989">
        mdi-plus
      </v-icon>
      <span class="ml-2">
        {{ btnText }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    inputDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      warehouseSelected: 'main',
      tabelHeader: [
        {
          text: 'Product',
          align: 'left',
          value: 'images',
          width: 'auto',
          sort: false
        },
        {
          text: 'Info',
          align: 'left',
          value: 'name',
          width: 150,
          sortable: false
        },
        {
          text: 'Amount',
          align: 'center',
          value: 'amount',
          width: this.$vuetify.breakpoint.xsOnly ? 100 : 'auto',
          sortable: false
        },
        {
          text: 'Price',
          align: 'right',
          value: 'price',
          width: 'auto',
          sortable: false
        },
        {
          text: 'Discount',
          align: 'center',
          value: 'discount',
          width: this.$vuetify.breakpoint.xsOnly ? 300 : 150,
          sortable: false
        },
        {
          text: 'Net',
          align: 'right',
          value: 'net',
          width: 100,
          sortable: false
        }
      ],
      discountType: [
        {
          label: '฿',
          value: 'amount'
        }
        // {
        //   label: '%',
        //   value: 'percent'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters({
      skus: 'Order/orderSkus',
      hasOrderSkus: 'Order/hasOrderSkus',
      orderDetail: 'Order/orderDetail',
      isMainOrder: 'Order/isMainOrder',
      editMode: 'Order/onEditMode',
      allSubOrders: 'Order/allSubOrders'
    }),
    getHeightTable () {
      if (this.$vuetify.breakpoint.xsOnly) return 'auto'
      const isEditProduct = this.inputDisabled ? 45 : 0
      const editHeight = this.editMode ? 47 : 0
      return (this.$vuetify.breakpoint.height - 400) + isEditProduct - editHeight
    },
    btnText () {
      return this.hasOrderSkus ? 'Edit Product' : 'Add Product'
    },
    tabHeaders () {
      const headers = [
        { name: 'main', id: 0 }
      ]
      const subOrders = this.orderDetail?.subOrders?.map((subOrder, index) => ({
        id: `${subOrder?.warehouse.id}${index}`,
        name: subOrder?.warehouse.name
      })) || []
      return [...headers, ...subOrders]
    },
    shippingCode () {
      return this.orderDetail?.shipping?.refCode || ''
    }
  },
  watch: {
    orderDetail () {
      this.initSubOrders()
    }
  },
  mounted () {
    this.initSubOrders()
  },
  methods: {
    ...mapActions({
      calcSkuPrice: 'Order/calcSkuPrice',
      setProductModalActive: 'Order/setProductModalActive',
      setAllSubOrders: 'Order/setAllSubOrders'
    }),
    isMain (id) {
      return id === 0
    },
    isAmountDiscount (type) {
      return type === 'amount'
    },
    getUnitPrice (sku) {
      // TODO Fix this if order sg
      return sku.unitPrice || sku.fullPrice / sku.amount
    },
    initSubOrders () {
      const rawSubOrders = this.orderDetail?.subOrders || []
      const subOrders = this.mapSubOrders(rawSubOrders)
      this.setAllSubOrders(subOrders)
    },
    mapSubOrders (subOrders) {
      const mapped = {}
      const mainSkus = []

      // Use Index and Added Warehouse Property
      subOrders.forEach((subOrder, index) => {
        mapped[`${subOrder.warehouse.id}${index}`] = subOrder.skus.map((sku) => ({
          ...sku,
          discountType: 'amount',
          discountPrice: sku.fullPrice - sku.price,
          unitPrice: sku.fullPrice / sku.amount,
          trackingNo: subOrder.shipping?.refCode || ''
        }))

        subOrder.skus.forEach((sku) => {
          const found = mainSkus.findIndex((mainSku) => mainSku.id === sku.id)
          if (found === -1) {
            mainSkus.push({
              ...sku,
              discountType: 'amount',
              discountPrice: sku.fullPrice - sku.price,
              unitPrice: sku.fullPrice / sku.amount
            })
          } else {
            mainSkus[found] = {
              ...mainSkus[found],
              mixAndMatch: mainSkus[found].mixAndMatch || sku.mixAndMatch,
              amount: mainSkus[found].amount + sku.amount,
              price: mainSkus[found].price + sku.price,
              discountPrice: mainSkus[found].discountPrice + sku.discountPrice || 0,
              fullPrice: mainSkus[found].fullPrice + sku.fullPrice
            }

            mainSkus[found].unitPrice = mainSkus[found].fullPrice / mainSkus[found].amount
          }
        })
      })

      mapped[0] = mainSkus

      return mapped
    },
    calcDiscountPerWarehouse (sku, detail) {
      const { discountType } = detail
      const discountPrice = discountType === 'amount' ? (detail.discountPrice / detail.amount) * sku.amount : detail.discountPrice

      return { ...sku, discountPrice, discountType }
    },
    openProductModal () {
      this.setProductModalActive(true)
    },
    imgSrcCompute (src) {
      return src || 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png'
    },
    getPreOrder (item) {
      return item?.preOrder ?? false
    }
  }
}
</script>

<style lang="scss">
.order-product-add-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f7f7f7;
  border: 2px dashed #898989;
  border-radius: 5px;
  color: #898989;
  font-size: 14px;
  font-weight: 500;
  margin: 10px auto 0;
  padding: 5px;
  width: 97%;
}
#order-product-table p.text-price {
  margin: 4px 0;
}
#order-product-table.order-product-empty table {
  height: 100%;
  td {
    height: 100%;
    button.v-btn {
      height: 100%;
      position: relative;
      z-index: 2;
    }
  }
}
.font-super-bold {
  font-size: 14px;
  font-weight: 800;
}
</style>
