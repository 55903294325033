<template>
  <div class="search-tag d-flex flex-column">
    <form @submit.prevent="searchProduct()">
      <div class="tags-container mb-1">
        <v-chip
          v-for="(tagSelected, index) in formData.tags"
          :key="`tag-selected-chip-${index}`"
          class="ma-1"
          color="teal"
          text-color="white"
          small
          label
          close
          @click:close="unSelectTag(index)">
          {{ tagSelected }}
        </v-chip>
      </div>
      <div class="d-flex align-center col-search">
        <v-text-field
          v-model.trim="formData.search"
          class="mr-2"
          hide-details
          placeholder="Name, Model"
          dense
          solo>
          <template v-slot:append>
            <v-icon x-small>
              {{ appendIcon }}
            </v-icon>
          </template>
        </v-text-field>
        <v-autocomplete
          v-model="selectedTags"
          :items="autoCompleteTags"
          :search-input.sync="tagSearch"
          multiple
          placeholder="Tags"
          filled
          solo
          hide-details
          hide-selected>
          <template v-slot:append>
            <v-icon x-small>
              mdi-tag
            </v-icon>
          </template>
          <template v-slot:selection>
            <span></span>
          </template>
        </v-autocomplete>
        <div class="tags-operation pl-2">
          <span class="text-caption mr-2">OR</span>
          <v-switch
            v-model="formData.tagOperation"
            class="ma-0"
            false-value="OR"
            true-value="AND"
            color="secondary"
            hide-details
            dense />
          <span class="text-caption">AND</span>
        </div>
        <v-btn
          class="ml-1"
          :min-width="0"
          :width="40"
          color="secondary"
          small
          type="submit">
          <v-icon x-small>
            mdi-magnify
          </v-icon>
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        search: '',
        tags: [],
        tagOperation: 'OR'
      })
    },
    appendIcon: {
      type: String,
      default: null
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tagSearch: ''
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    autoCompleteTags () {
      return this.tags.map((tag) => tag.name)
    },
    selectedTags: {
      get () {
        return this.formData.tags
      },
      set (val) {
        this.formData.tags = val
        this.tagSearch = ''
      }
    }
  },
  methods: {
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.formData.tags[index])
      if (tagIndex !== -1) {
        this.formData.tags.splice(index, 1)
      }
    },
    searchProduct () {
      this.$emit('on-search', this.formData)
    }
  }
}
</script>

<style scoped>
.col-search {
  width: 100%;
}
.search-tag {
  width: 100%;
}
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.tags-operation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.search-tag .v-input__control {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
.search-tag .v-input__slot {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
</style>
