<template>
  <v-row
    justify="center"
    align="center"
    no-gutters>
    <v-col
      cols="3"
      sm="6"
    >
      <p class="caption mb-0">
        Note
      </p>
    </v-col>
    <v-col
      cols="7"
      sm="5"
    >
      <p
        v-if="$vuetify.breakpoint.xsOnly"
        class="caption text-end mb-0">
        {{ orderDetail.note || 'ไม่ระบุ' }}
      </p>
      <v-text-field
        v-else
        :value="orderDetail.note"
        label="Order Note"
        class="order-summary-input"
        :disabled="inputDisable"
        autocomplete="off"
        dense
        solo
        outlined
        hide-details
        @input="updateNote($event)" />
    </v-col>
    <v-col
      cols="2"
      sm="1"
    ></v-col>
    <v-col
      cols="3"
      sm="6"
    >
      <p class="caption mb-0">
        Total
      </p>
    </v-col>
    <v-col
      cols="7"
      sm="5"

      class="text-end">
      <p class="caption mb-0">
        {{ skusTotalPrice | showFullPriceFormat() }}
      </p>
    </v-col>
    <v-col
      cols="2"
      sm="1"

      class="text-center">
      <p class="caption mb-0">
        {{ currencyUnit }}
      </p>
    </v-col>
    <v-col
      cols="3"
      sm="6"
    >
      <p class="caption mb-0">
        Promotion Code
      </p>
    </v-col>
    <v-col
      cols="7"
      sm="5"
    >
      <div>
        <p
          v-if="$vuetify.breakpoint.xsOnly"
          class="caption text-end mb-0">
          {{ checkPromocode(orderDetail.promotionCode) || 'ไม่ระบุ' }}
        </p>
        <v-text-field
          v-else
          :value="checkPromocode(orderDetail.promotionCode)"
          label="Promotion Code"
          class="order-summary-input promotion-code-input"
          autocomplete="off"
          :disabled="inputDisable"
          :loading="gettingCode"
          dense
          solo
          outlined
          hide-details
          @input="updatePromotionCode($event)" />
      </div>
    </v-col>
    <v-col
      cols="2"
      sm="1"
      class="text-center px-1">
      <v-btn
        v-if="!inputDisabled"
        x-small
        :disabled="gettingCode || !editMode"
        @click="isVerifiedCode ? cancelVerifyCode() : verifyCode()">
        {{ isVerifiedCode ? 'Cancel' : 'Verify' }}
      </v-btn>
    </v-col>
    <v-col
      cols="3"
      sm="6"
    >
      <p class="caption mb-0">
        Code Discount
      </p>
    </v-col>
    <v-col
      cols="7"
      sm="5"

      class="text-end">
      <p class="caption mb-0">
        {{ codeDiscount | showFullPriceFormat() }}
      </p>
    </v-col>
    <v-col
      cols="2"
      sm="1"

      class="text-center"
    >
      <p class="caption mb-0">
        {{ currencyUnit }}
      </p>
    </v-col>
    <v-col
      cols="8"
      sm="2"
    >
      <p
        v-if="$vuetify.breakpoint.xsOnly"
        class="caption mb-0">
        Shipping Cost ({{ shipping.providerName }})
      </p>
      <p
        v-else
        class="caption mb-0">
        Shipping Cost
      </p>
    </v-col>
    <v-col
      v-if="!$vuetify.breakpoint.xsOnly"
      cols="3">
      <v-select
        :value="shipping"
        :items="shippingItems"
        :menu-props="{
          offsetY: true,
          contentClass: 'order-summary-select-menu'
        }"
        item-text="providerName"
        item-value="method"
        class="order-summary-select"
        return-object
        hide-details
        dense
        outlined
        :disabled="inputDisabled || !editMode"
        @change="setShipping($event)">
        <template v-slot:item="{ item }">
          <p class="text-uppercase">
            {{ item.providerName }}
          </p>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="2"
      sm="5"
      offset-sm="1"
      md="5"
      offset-md="1"
      lg="5"
      offset-lg="1"
      xl="5"
      offset-xl="1"
      class="text-end">
      <p
        v-if="$vuetify.breakpoint.xsOnly"
        class="caption text-end mb-0">
        {{ shippingFee || '0.00' }}
      </p>
      <v-text-field
        v-else
        :value="shippingFee"
        class="shippingfee-input order-summary-input"
        dense
        solo
        outlined
        hide-details
        :disabled="inputDisabled || !editMode"
        @keypress="$keypressNumber($event)"
        @input="setShippingFee($event)">
      </v-text-field>
    </v-col>
    <v-col
      cols="2"
      sm="1"

      class="text-center">
      <p class="caption mb-0">
        {{ currencyUnit }}
      </p>
    </v-col>
    <v-col
      cols="3"
      sm="6"
    >
      <p class="caption mb-0">
        Net
      </p>
    </v-col>
    <v-col
      cols="7"
      sm="5"

      class="text-end">
      <p class="caption mb-0">
        {{ netPrice | showFullPriceFormat() }}
      </p>
    </v-col>
    <v-col
      cols="2"
      sm="1"

      class="text-center">
      <p class="caption mb-0">
        {{ currencyUnit }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    verifiedCode: {
      type: Boolean,
      default: false
    },
    inputDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      gettingCode: false
    }
  },
  computed: {
    ...mapGetters({
      shippingItems: 'Store/mapShipping',
      shipping: 'Order/shipping',
      skusTotalPrice: 'Order/skusTotalPrice',
      skusDiscount: 'Order/skusDiscount',
      codeDiscount: 'Order/codeDiscount',
      shippingFee: 'Order/shippingFee',
      netPrice: 'Order/netPrice',
      orderDetail: 'Order/orderDetail',
      isVerifiedCode: 'Order/isVerifiedCode',
      editMode: 'Order/onEditMode',
      orderDetailActive: 'Order/orderDetailActive',
      isGotMember: 'Order/isGotMember',
      currencyUnit: 'Order/currencyUnit'
    }),
    inputDisable () {
      return this.gettingCode || this.isVerifiedCode || this.inputDisabled || !this.editMode
    },
    currentState () {
      return this.orderDetail.currentState
    }
  },
  methods: {
    ...mapActions({
      setShipping: 'Order/setShipping',
      setShippingFee: 'Order/setShippingFee',
      setPromotionCode: 'Order/setPromotionCode',
      setNote: 'Order/setNote',
      getMember: 'Order/getMember',
      updateGotMember: 'Order/updateGotMember',
      validatePromotion: 'Order/validatePromotion',
      cancelVaildateCode: 'Order/cancelVaildateCode',
      setSnackbar: 'Components/setSnackbar'
    }),
    updateNote (value) {
      this.setNote(value)
    },
    updatePromotionCode (value) {
      this.setPromotionCode(value)
    },
    checkPromocode (promotion) {
      return promotion?.code || null
    },
    async verifyCode () {
      try {
        this.gettingCode = true
        await this.validatePromotion(this.orderDetail.promotionCode.code)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.gettingCode = false
      }
    },
    cancelVerifyCode () {
      this.cancelVaildateCode()
    }
  }
}
</script>
