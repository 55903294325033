<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      sm="6"
    >
      <p :class="textPropertyClass">
        <b>Order ID :</b>
        {{ orderDetail.orderId }}
        <span
          v-if="orderDetail.orderId"
          :class="`status-dot status-dot-${orderDetail.currentState}`" />
      </p>
      <p :class="`${textPropertyClass} d-flex align-center`">
        <b>Channel</b>
        <v-select
          v-model="orderChannel"
          :items="orderChannels"
          :readonly="orderDetail.currentState !== 'prepare_create'"
          class="expire-date-text-field channel-select-field mx-2"
          dense
          hide-details />
      </p>
      <p :class="textPropertyClass">
        <b>Link : </b>
        <a
          :href="`${billUrl}/th/${orderDetail.url}`"
          target="_blank">{{ orderDetail.url }}</a>
      </p>
      <p :class="textPropertyClass">
        <b>MyShop ID : </b>
        <span> {{ orderDetail.lineMyShopOrderId ? orderDetail.lineMyShopOrderId : '-' }}</span>
      </p>
      <p :class="textPropertyClass">
        <b>Regen From : </b>
        <span> {{ orderDetail.regenFrom && orderDetail.regenFrom.orderId ? orderDetail.regenFrom.orderId : '-' }}</span>
      </p>
    </v-col>
    <v-col
      cols="12"
      sm="6">
      <p :class="textPropertyClass">
        <b>Date :</b>
        {{ orderCreateTime }}
      </p>
      <div
        :class="`text-error d-flex align-center ${lineGap}`">
        <p :class="`${fontType} mb-0`">
          <b>Expired Date :</b>
        </p>
        <v-dialog
          ref="modalDate"
          v-model="modalDate"
          :disabled="!editMode"
          persistent
          width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="expireDateText"
              class="expire-date-text-field"
              dense
              readonly
              hide-details
              v-bind="attrs"
              v-on="on">
              <template v-slot:prepend-inner>
                <v-icon small>
                  mdi-calendar
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="expireDate"
            :min="minDate"
            scrollable>
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="setModalDate(false)">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="setModalDate(false)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
      <p
        :class="`${textPropertyClass} d-flex align-center`"
      >
        <b>Brand : </b>
        <v-select
          v-model="orderBrand"
          :items="brands"
          :readonly="brandSelectable"
          class="expire-date-text-field brand-select-field mx-2"
          dense
          hide-details />
      </p>
      <p :class="`${textPropertyClass} d-flex flex:-row flex-wrap`">
        <b>Tags :</b>
        <v-chip
          v-for="(tag, i) in orderDetail.tags"
          :key="i"
          class="ma-1"
          x-small
          color="orange"
          dark
        >
          {{ tag | toCapitalize() }}
        </v-chip>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const ProductAttributeService = new ProductAttributeProvider()
export default {
    props: {
    fontType: {
      type: String,
      default: 'text-caption'
    },
    lineGap: {
      type: String,
      default: 'mb-0'
    }
  },
  data () {
    return {
      modalDate: false,
      orderChannels: [
        { value: 'manual', text: 'MANUAL' },
        { value: 'celeb', text: 'CELEB' },
        { value: 'international_manual', text: 'INTERNATIONAL MANUAL' },
        { value: 'tiktok', text: 'TIKTOK' }
      ],
      brands: []
    }
  },
  computed: {
    ...mapGetters({
      orderDetail: 'Order/orderDetail',
      editMode: 'Order/onEditMode',
      billUrl: 'Store/linkBill'
    }),
    expireDate: {
      get () {
        if (this.orderDetail?.expire) {
          return this.$dayjs(this.orderDetail.expire).format('YYYY-MM-DD')
        }
        return null
      },
      set (newValue) {
        this.actionExpireDate(newValue)
      }
    },
    expireDateText () {
      if (this.orderDetail?.expire) {
        return this.$dayjs(this.orderDetail.expire).format('DD MMM YYYY HH:mm:ss')
      }
      return ''
    },
    minDate () {
      return this.$dayjs().add(1, 'd').format('YYYY-MM-DD')
    },
    orderCreateTime () {
      if (this.orderDetail.createdAt) {
        return this.$dayjs(this.orderDetail.createdAt).format('DD MMM YYYY HH:mm:ss')
      }
      return ''
    },
    currentStatus () {
      return this.orderDetail.status
    },
    orderChannel: {
      get () {
        const { channel } = this.orderDetail
        const allChannels = this.orderChannels.map((orderChannel) => orderChannel.value)
        if (!allChannels[channel]) {
          this.addMoreChannelInSelect({ value: channel, text: `${channel.toUpperCase()}` })
        }

        return this.orderDetail.channel
      },
      set (value) {
        this.setOrderDetailChannel(value)
      }
    },
    orderBrand: {
      get () {
        return this.orderDetail.brand
      },
      set (value) {
        this.setOrderDetailBrand(value)
      }
    },
    orderShipment () {
      return this.orderDetail?.shipping?.method.toUpperCase() || ''
    },
    brandSelectable () {
      return this.orderDetail.currentState !== 'edit' && this.orderDetail.currentState !== 'prepare_create'
    },
    textPropertyClass () {
      return `${this.fontType} ${this.lineGap}`
    }
  },
  async mounted () {
    await this.getBrand()
  },
  methods: {
    ...mapActions({
      setExpireDate: 'Order/setExpireDate',
      setOrderDetailChannel: 'Order/setOrderDetailChannel',
      setOrderDetailBrand: 'Order/setOrderDetailBrand'
    }),
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.brands = data.results.map((brand) => brand.name)
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    addMoreChannelInSelect (channel) {
      this.orderChannels = ([...this.orderChannels, channel])
    },
    setModalDate (value = false) {
      this.modalDate = value
    },
    actionExpireDate (date = this.$dayjs().add(1, 'd').format('YYYY-MM-DD')) {
      const newDate = this.$dayjs(date).hour(23)
                      .minute(59)
                      .second(59)
                      .millisecond(999)
                      .toISOString()
      this.setExpireDate(newDate)
    }
  }
}
</script>

<style lang="scss">
#order-modal {
  .v-input.expire-date-text-field.v-text-field {
    margin-top: 0;
    input {
      padding: 0;
    }
  }
  .status-dot {
    margin-left: 12px;
  }
  .channel-select-field {
    max-width: 158px;
    div.v-select__slot {
      max-height: 20px;
    }
  }
  .brand-select-field {
    max-width: 158px;
    div.v-select__slot {
      max-height: 20px;
    }
  }
}

</style>
