import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ProductProvider extends HttpRequest {
  createProduct (payload) {
    this.setHeader(getAuthToken())
    return this.post('/products', payload)
  }

  deleteProduct (queryId) {
    this.setHeader(getAuthToken())
    return this.delete('/products', queryId)
  }

  updateProduct (param, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/products/${param}`, payload)
  }

  getProducts (query) {
    this.setHeader(getAuthToken())
    return this.get('/products', query)
  }

  getProductsList (query) {
    this.setHeader(getAuthToken())
    return this.get('/products/list', query)
  }

  getMyShopProducts (query) {
    this.setHeader(getAuthToken())
    return this.get('/products/myshop-products', query)
  }

  getMyShopStocksByProductId (id) {
    this.setHeader(getAuthToken())
    return this.get(`/products/${id}/myshop-stocks`)
  }

  getSkuCodeByAliasId (id) {
    this.setHeader(getAuthToken())
    return this.get(`/products/${id}/sku-alias`)
  }

  getSkuAliasIdByCode (code) {
    this.setHeader(getAuthToken())
    return this.get(`/products/${code}/sku-code`)
  }

  getProductsByPaginate (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/products', query)
  }

  getProductsForMapping (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/products/for-map-product', query)
  }

  getProductsForSorting (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/products/for-sort-products', query)
  }

  updatePriorityAndIsOnWeb (payload) {
    this.setHeader(getAuthToken())
    return this.patch('/products/priority-and-onweb', payload)
  }

  getProductById (param) {
    this.setHeader(getAuthToken())
    return this.get(`/products/${param}`)
  }

  getManyProductById (payload) {
    this.setHeader(getAuthToken())
    return this.post('/products/many', payload)
  }

  getManyProductByModel (payload) {
    this.setHeader(getAuthToken())
    return this.post('/products/many-by-model', payload)
  }

  getManyProductByCode (payload) {
    this.setHeader(getAuthToken())
    return this.post('/products/many-by-code', payload)
  }

  getAttributes () {
    this.setHeader(getAuthToken())
    return this.get('/products/attributes')
  }

  exportProductStock (query) {
    this.setHeader(getAuthToken())
    return this.get('/products/export-product-stock', query)
  }

  fetchProductsByAliasIds (aliasIds) {
     this.setHeader(getAuthToken())
     return this.post('/products/get-by-alias-ids', aliasIds)
  }
}

export default ProductProvider
