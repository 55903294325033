<template>
  <v-card
    class="order-detail-card"
    height="100%">
    <v-col
      :cols="12">
      <h3>Order History</h3>
    </v-col>
    <v-col
      :cols="12"
      class="order-timeline">
      <v-row
        v-for="(history) in orderDetail.stateHistories"
        :key="`history-${history.id}`"
        class="order-timeline-item"
        align="center">
        <v-col :cols="4">
          <div :class="`order-timeline-circle order-timeline-status-${history.state}`">
            <gw-svg-icon
              :svg-name="`order-${history.state}`"
              width="100%"
              height="100%"
              class="order-timeline-icon"
              color="#ffffff" />
          </div>
        </v-col>
        <v-col
          :cols="8"
          class="pl-0 order-timeline-detail">
          <p class="caption">
            {{ mapStateHistory(history.state) }}
          </p>
          <p>By : {{ history.createdBy.email }}</p>
          <p>{{ getTime(history.createdAt) }}</p>
          <p>{{ history.note }}</p>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // history: {
    //   type: Array,
    //   default: () => []
    // }
  },
  computed: {
    ...mapGetters({
      orderDetail: 'Order/orderDetail'
    })
  },
  methods: {
    getTime (time) {
      return this.$dayjs(time).format('DD MMM YYYY HH:mm')
    },
    mapStateHistory (state) {
      switch (state) {
        case 'prepare_create': return 'Prepare Create'
        case 'add_tracking_no': return 'Add Tracking No'
        case 'update_order': return 'Update Order'
        case 'pending': return 'Pending'
        case 'reserved': return 'Reserved'
        case 'waiting': return 'Waiting'
        case 'update_shipping': return 'Update Shipping'
        case 'paid': return 'Paid'
        case 'closed': return 'Closed'
        case 'ready_to_ship': return 'Ready to ship'
        case 'deduct_stock': return 'Deduct Stock'
        case 'completed': return 'Completed'
        case 'kick': return 'Kick'
        default: return state
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/gw-variables.scss';

.order-timeline {
  .order-timeline-circle {
    display: block;
    width: 100%;
    padding-top: 100%;
    margin: 0 auto;
    border-radius: 100%;
    background-color: $bill-status;
    position: relative;
  }
  .order-timeline-status-update_order {
    background-color: $bill-status-update_order;
  }
  .order-timeline-status-add_tracking_no {
    background-color: $bill-status-add_tracking_no;
  }
  .order-timeline-status-pending {
    background-color: $bill-status-pending;
  }
  .order-timeline-status-reserved {
    background-color: $bill-status-reserved;
  }
  .order-timeline-status-update_shipping {
    background-color: $bill-status-update_shipping;
  }
  .order-timeline-status-paid {
    background-color: $bill-status-paid;
  }
  .order-timeline-status-waiting {
    background-color: $bill-status-waiting;
  }
  .order-timeline-status-ready_to_ship {
    background-color: $bill-status-readytoship;
  }
  .order-timeline-status-completed {
    background-color: $bill-status-completed;
  }
  span.status-dot.status-dot-expired {
    background-color: $bill-status-expired;
  }

  .order-timeline-status-void {
    background-color: $bill-status-void;
  }
  .order-timeline-status-exchange_return {
    background-color: $bill-status-exchange_return;
  }
  .order-timeline-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  p {
    margin-bottom: 0;
  }
}
.order-timeline {
  position: relative;
}
.order-timeline:before {
  content: '';
  width: 2px;
  top: 0px;
  height: calc(95% - 25px);
  background-color: #adadad;
  position: absolute;
  transform: translateX(-50%);
  left: 16.5%;
}
.order-timeline-detail {
  font-size: 0.5rem;
}
p.caption {
  text-transform: capitalize;
}
.v-input.order-input .v-input__control {
  min-height: 0;
  width: 40px;
  font-size: 12px;
}
.v-input.order-input input {
  text-align: center;
  padding: 10px 0;
}
</style>

<style>
.order-timeline-icon svg {
  fill: #ffffff;
}
</style>
